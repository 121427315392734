/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";
import React from "react";
import { Link } from "gatsby";
interface ComponentProps {
  items: Array<any>;
  hideType?: Boolean;
  clickable?: boolean;
}

const TagDuo = ({ items, hideType, clickable }: ComponentProps) => (
  <p
    sx={{
      variant: "typography.headingSmall",
      my: 0,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    }}
  >
    {items.map((i, index) => (
      <React.Fragment key={index + "tag"}>
        {clickable ? (
          <Link
            to={`/tags/${i.slug}`}
            sx={{
              textDecoration: "none",
              color: "inherit",
              display:
                index === 0 && hideType ? ["none", "inline"] : ["inline"],
              letterSpacing: "-0.25px",
            }}
          >
            {i.title}
          </Link>
        ) : (
          <span
            sx={{
              display:
                index === 0 && hideType ? ["none", "inline"] : ["inline"],
              letterSpacing: "-0.25px",
            }}
          >
            {i}
          </span>
        )}

        {index !== items.length - 1 ? (
          <span
            sx={{
              display: hideType ? ["none", "block"] : "block",
              borderLeft: "1px solid black",
              height: "16px",
              mx: rem(10),
            }}
          />
        ) : null}
      </React.Fragment>
    ))}
  </p>
);

export default TagDuo;
