const mapEntry = (entry) => {

  if (entry) {
    return {
      title: entry.title,
      heroImage: entry.heroImage[0] || null,
      introductoryText: entry.introductoryText,
      sections: entry.bodySections.map(({ __typename, ...props }) => {
        switch (__typename) {
          case "Craft_bodySections_html_BlockType":
            return {
              type: __typename,
              content: props.body,
            };
        }
      }),
    };
  }

  return {}
  
};

export const mapCraft = (craft) => {
  return {
    entry: mapEntry(craft.entry),
  };
};
