/** @jsx jsx */
import { jsx } from "theme-ui";
import striptags from "striptags";
import { graphql } from "gatsby";
import { mapCraft } from "./mappings";
import Layout from "@components/layouts";
import SEO from "@src/components/seo";
import Masthead from "@components/Masthead";
import { SectionBlock } from "@components/ContentBlocks";

const PageTemplate = (props) => {
  const { entry } = mapCraft(props.data.craft);
  const { heroImage, title, introductoryText, sections } = entry;

  const strippedIntroText = striptags(introductoryText);

  return (
    <Layout fixedSidebar={true} location={props.location}>
      <SEO
        location={props.location}
        defaultTitle={title}
        type="article"
        metadata={{
          metaDescription: strippedIntroText,
          socialImage: heroImage ? heroImage.url : "",
        }}
      />

      <Masthead
        introductoryText={introductoryText}
        title={title}
        heroImage={heroImage}
      />

      {sections.map((section) => (
        <SectionBlock {...section} />
      ))}
    </Layout>
  );
};

export default PageTemplate;

export const query = graphql`
  query PageQuery($id: Craft_QueryArgument) {
    craft {
      entry(id: [$id]) {
        ...pageFields
      }
    }
  }
`;
