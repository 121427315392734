/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { Section } from "@components/ContentBlocks";
import { Grid } from "@components/primitives/Grid";
import TagDuo from "@src/components/TagDuo";

import { rem } from "@src/theme";

interface ComponentProps {
  heroImage?: {
    url: string;
  };
  title: string;
  introductoryText?: string;
  tags?: Array<string>;
  date?: string;
}
const Masthead = ({
  heroImage,
  title,
  introductoryText: __html,
  tags,
  date,
}: ComponentProps) => (
  <Grid>
    <Section>
      {heroImage && (
        <img
          src={heroImage.url}
          sx={{
            width: "100%",
            mb: rem(20),
          }}
        />
      )}
      {tags && tags.length ? <TagDuo items={tags} clickable={true} /> : null}
      <Styled.h1
        sx={{
          mt: rem(16),
          mb: 0,
          variant: "typography.headingXLarge",
          wordBreak: "break-word",
        }}
      >
        {title}
      </Styled.h1>
      {__html && (
        <div
          sx={{
            fontFamily: "body",
            fontSize: "32px",
            lineHeight: 1.2,

            mt: rem(24),
            mb: rem(28),
            "> :first-child": {
              mt: rem(0),
            },
            "> :last-child": {
              mb: rem(0),
            },
          }}
          dangerouslySetInnerHTML={{ __html }}
        />
      )}

      {date && (
        <p
          sx={{
            variant: "typography.headingSmall",
          }}
        >
          {date}
        </p>
      )}

      <hr
        sx={{
          bg: "coal",
          height: "1px",
          border: "none",
        }}
      />
    </Section>
  </Grid>
);

export default Masthead;
